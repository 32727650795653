import { decodeServerTime, encodeDate } from "../../services/connection.client";

export class TIUtilities {
    public static decodeServerTime(source: string): Date | undefined {
        return decodeServerTime(source);
    }

    public static toTimeT(date: Date): number {
        return encodeDate(date);
    }

    public static xmlToJsonMapper(rawXML: Element): Array<any> {
        const result: Array<any> = [];
        if (rawXML.children?.length) {
            const children = rawXML.children.item(0)?.children;
            if (children) {
                for (const child of Array.from(children)) {
                    const model: any = {};
                    for (const attr of Array.from(child.attributes)) {
                        model[attr.name] = attr.value;
                    }

                    result.push(model);
                }
            }
        }

        return result;
    }

    public static parseCloudLinkXMLToJsonMapper(rawXML: Element, fromType: string, attributes: Array<string>): any {
        const windowNodes = rawXML.getElementsByTagName("WINDOW");

        let firstFoundWindow: Element | null = null;
        for (var i = 0; i < windowNodes.length; i++) {
            if (windowNodes[i].getAttribute("FORM_TYPE") == fromType) {
                firstFoundWindow = windowNodes[i];
                break;
            }
        }

        if (firstFoundWindow) {
            const model: any = {};

            for (const attr of Array.from(firstFoundWindow.attributes)) {
                if (attributes?.length && attributes.some((x: string) => x.toLowerCase() == attr.name.toLowerCase())) {
                    model[attr.name] = attr.value;
                }
            }

            return model;
        }

        return null;
    }

    public static removeCData(xmlString: string) {
        if (xmlString) {
            if (xmlString.startsWith('<![CDATA[') && xmlString.endsWith(']]>')) {
                let xmlStringtoReturn = xmlString.slice(9, xmlString.length);
                xmlStringtoReturn = xmlStringtoReturn.slice(0, xmlStringtoReturn.length - 3);
                return xmlStringtoReturn;
            }
        }

        return xmlString;
    }
}