export class BrokerOrder {
    public AccountId:string | undefined = undefined;
    public ParentOrderId:string | undefined = undefined;
    public OrderId:string | undefined = undefined;
    public IsLong:boolean | undefined = undefined;
    public IsEntryOrder:boolean | undefined = undefined;
    public BrokerOrderId:string | undefined = undefined;
    public Side:string | undefined = undefined;
    public Symbol:string | undefined = undefined;
    public TradeType:string | undefined = undefined;
    public Status:string | undefined = undefined;
    public ErrorReason:string | undefined = undefined;
    public OcaGroup:string  = "";
    public OrderReference:string = "";
    public FilledPrice:number | undefined = undefined;
    public Shares:number | undefined = undefined;
    public FilledShares:number | undefined = undefined;
    public LimitPrice:number | undefined = undefined;
    public StopPrice:number | undefined = undefined;
    public OutsideRTH:boolean | undefined = undefined;
    public GoodUntilTime:Date | undefined = undefined;
    public GoodAfterTime:Date | undefined = undefined;
    public Created:Date | undefined = undefined;
    public Updated:Date | undefined = undefined;
    public Filled:Date  | undefined = undefined;
}