import { parseXml2, testXml2 } from "./polyfill-DomParser";
import { dateIsValid, makePromise, parseFloatX } from "phil-lib/misc";
import { ConnectionSettings } from "../connection-settings";
//import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";
import { Callbacks, WebSocketTunnelConnection } from "./websocket-tunnel";

/**
 * Call this to cancel a previous request.
 *
 * It is _safe_ to call this even if the response has already been received, the request was already canceled, etc.
 *
 * This does __not__ send any information to the server.
 * The caller might have to tell the server
 *
 * In certain circumstances this might prevent a message from being sent to the server.
 * There are no guarantees.
 * Most of the time the message will be sent long before a cancel is requested.
 *
 * This call cleans up some memory on the client side.
 * We delete the callback, which will save some memory.
 *
 * If the server continues to send messages, they will __not__ be delivered.
 * Even if you tell the server you are no longer interested in certain messages, there may be some messages in transit.
 */
export type CancelToken = () => void;

/**
 * Use this key to select which command you are trying to access.
 * The server will implement a number of different commands.
 */
export const COMMAND = "command";

/**
 * Assigned by this library on outgoing messages.
 * We will match this with responses, to make sure the responses are mapped correctly.
 */
const MESSAGE_ID = "message_id";

/**
 * A message that we want to send to the server.
 *
 * This should include a `COMMAND` field, if nothing else.
 * The rest of the details depend on the command.
 *
 * This should __not__ include a `message_id` field.
 * That will be added, if required, by this library.
 *
 * Numbers and booleans will automatically get converted to strings in the expected way.
 * Strings will get converted into utf-8.
 * Null or undefined means to skip this key.  Dates will be converted to a simple
 * decimal format, "0" for invalid.
 *
 * We should be able to handle a Uint8Array.  But I'm not going to bother adding it until
 * I have to.  For now I'm assuming all outgoing data will be valid utf-8.
 */
export type MessageToServer = ReadonlyMap<
  string,
  string | number | null | undefined | boolean | Date
>;

/**
 * Create and initialize a MessageToServer object from an array.
 *
 * This should be trivial, but sometimes TypeScript gets confused because
 * of the complicated types.  Calling this function, instead of calling
 * `new Map()` directly, will make your life easier.
 * @param pairs The initial name value pairs for the request.
 * @returns A MessageToServer with these items.
 */
export function createMessageToServer(
  pairs: [string, string | number | null | undefined | boolean | Date][]
) {
  return new Map(pairs);
}

/**
 *
 * @param message
 * @returns The command name, taken from the message.
 * @throws An error if the message is not valid.
 */
export function getCommandName(message: MessageToServer): string {
  const commandName = message.get(COMMAND);
  if (typeof commandName !== "string" || message.has(MESSAGE_ID)) {
    console.error("Invalid MessageToServer", message);
    throw new Error("Invalid MessageToServer");
  }
  return commandName;
}

/**
 * Convert a JavaScript `Date` into the format that our servers use.
 *
 * In the C# code this was called ServerFormats.ToTimeT().
 *
 * See decodeServerTime() for the reverse operation().
 * @param date The date that we want to send to the server.
 * @returns The date in time_t format, rounded down to an integer.
 */
export function encodeDate(date: Date): number {
  return dateIsValid(date) ? Math.floor(date.valueOf() / 1000) : 0;
}

/**
 * Marshal the outgoing data.
 *
 * Notice that we are doing two different levels of marshalling:
 * 1) We take individual values, like `true` and and convert them to strings,
 *    like "1", in a standard way.  The main body of the server code **will**
 *    see these strings there are standard library routines to convert them
 *    back from strings.
 * 2) We take a Map of values that makes up the entire message and convert
 *    this into a single string for transmission.  There are multiple ways
 *    to do this and this code uses the simplest option.  Only one small part
 *    of the server knows or cares about this part.  The main part of the
 *    server sees a map from strings to strings.
 * @param message What to send
 * @param messageId 0, the default, means none.
 * @returns A string that we could send to the server via Telnet, TCP/IP, our our new WebSocket tunnel.
 */
function encodeMessage(message: MessageToServer, messageId = 0): string {
  // We are basically doing uri encoding.
  // The message is like the part of a uri after the question mark.
  let result = "";
  function addSeparator() {
    if (result != "") {
      result += "&";
    }
  }
  for (const [key, value] of message.entries()) {
    if (value === null || value === undefined) {
      // This can be useful sometimes.  When we create the array we add all
      // of the possible keys.  Then we have a function generate the values.  And the
      // function might return null or undefined.  In that case this function is
      // responsible fo removing any keys where the data is missing.
      continue;
    }
    addSeparator();
    result += encodeURIComponent(key);
    result += "=";
    if (value instanceof Date) {
      const asTimeT = encodeDate(value);
      result += asTimeT;
    } else if (value === true) {
      result += "1";
    } else if (value === false) {
      result += "0";
    } else if (typeof value == "number") {
      result += value;
    } else {
      result += encodeURIComponent(value);
    }
  }
  if (messageId) {
    addSeparator();
    result += MESSAGE_ID + "=" + messageId;
  }
  result += "\r\n";
  //console.log(result, message, messageId);
  return result;
}

/**
 * Translate a date and time between the server format and the JavaScript format.
 *
 * For the most part dates are converted to time_t then to a decimal string.
 * But there are other options on the server, and it's best if all code uses
 * this one function.
 *
 * See encodeDate() for the reverse operation.
 * @param source A time and date sent to us by the server.
 * @returns A `Date` if possible.  `undefined` if there are any errors, including
 * `undefined` as an input.
 */
export function decodeServerTime(source: undefined): undefined;
export function decodeServerTime(source: string | undefined): Date | undefined;
export function decodeServerTime(source: string | undefined): Date | undefined {
  const asNumber = parseFloatX(source);
  if (asNumber === undefined) {
    return undefined;
  } else {
    return new Date(asNumber * 1000);
  }
}

/**
 * An Array of bytes with a raw response from the server.
 * Or undefined to report an error.
 */
export type ResponseFromServer = ArrayLike<number> | undefined;

/**
 * Sometimes the server sends binary data.  But most of the messages are just strings.
 * @param response A reply from the server.
 * @returns undefined if the input is undefined.
 * Otherwise it does it's best to convert the input into a string.
 * If the bytes are not valid unicode, the function still return a string, and it will do its best with what it has.
 */
export function responseToString(response: ArrayLike<number>): string;
export function responseToString(response: undefined): undefined;
export function responseToString(
  response: ResponseFromServer
): string | undefined;
export function responseToString(
  response: ResponseFromServer
): string | undefined {
  if (response === undefined) {
    // undefined in this context, means that there was an error.
    // We were expecting a response from the server, but we're not going to get it.
    // Pass the error condition on.
    return undefined;
  } else {
    const decoder = new TextDecoder();
    const decodable =
      response instanceof Uint8Array ? response : new Uint8Array(response);
    return decoder.decode(decodable);
  }
}

/**
 * What do we do when we get a response from the server?
 * Each outstanding message id will point to one of these.
 */
type InternalListener = {
  readonly command: string;
  forward(response: ResponseFromServer): void;
};

/**
 * * "initial" - The constructor creates a new WebSocket and puts us into the "initial" state.
 * * "active" - WebSocket confirms that it has connected.  You don't have to wait until you get here before you connect, but you might want to.
 * * "done" - The WebSocket is disconnected.  It will not be reconnected.
 */
export type State = "initial" | "active" | "done";

function join(...sources: Uint8Array[]): Uint8Array {
  const length = sources.reduce(
    (accumulator, source) => accumulator + source.length,
    0
  );
  const result = new Uint8Array(length);
  let offset = 0;
  sources.forEach((source) => {
    result.set(source, offset);
    offset += source.length;
  });
  if (offset != length) {
    throw new Error("wtf");
  }
  return result;
}

/**
 * Send messages to the server and forward any responses.
 *
 * This class will marshal any outgoing messages into a stream of bytes and send the bytes to a WebSocket.
 * And it will and un-marshal any responses received from the WebSocket.
 *
 * This class represents a single connection to the server.
 * If you wish to reconnect to the server, you will need to create a new one of these objects.
 */
export class TalkWithServer {
  #state: State = "initial";

  /**
   * If someone sends a message before we are connected, it gets queued here.
   *
   * I added this to make TalkWithServer flexible.  Currently the main program
   * does not send anything to TalkWithServer until TalkWithServer says it is
   * ready.
   */
  #queued = "";

  /**
   * Report when we enter the "active" #state.
   */
  #reportConnected: () => void;

  /**
   * Report when we enter the "done" #state.
   */
  #reportClosed: () => void;

  /**
   * Report when go directly from the "initial" #state to the "done" #state
   * without stopping in "active".
   */
  #reportFailed: () => void;

  /**
   * This is the only approved way to change this.#state.
   *
   * This takes care of things like reporting status, breaking the network connection,
   * and checking for illegal transitions.
   * @param newState The new value.
   */
  private setState(newState: State) {
    const previousState = this.#state;
    this.#state = newState;
    switch (previousState) {
      case "initial": {
        if (newState == "active") {
          if (this.#queued != "") {
            this.#connection.sendMessage(this.#queued);
            this.#queued = "";
          }
          this.#reportConnected();
        } else if (newState == "done") {
          this.#reportFailed();
          this.#queued = "";
        }
        break;
      }
      case "active": {
        if (newState == "initial") {
          throw new Error("wtf");
        } else if ((newState = "done")) {
          this.#connection.close();
          this.#reportClosed();
        }
        break;
      }
      case "done": {
        if (newState != previousState) {
          throw new Error("wtf");
        }
        break;
      }
      default: {
        throw new Error("wtf");
      }
    }
  }
  /**
   * Send bytes to the server.
   */
  private sendBytes(toSend: string) {
    switch (this.#state) {
      case "initial": {
        this.#queued += toSend;
        break;
      }
      case "active": {
        this.#connection.sendMessage(toSend);
        break;
      }
    }
  }

  /**
   * This sends a stream of bytes to the server
   * and receives a stream of bytes from the server.
   */
  #connection: WebSocketTunnelConnection;

  constructor() {
    const initializedPromise = makePromise<"connected" | "failed">();
    this.initialized = initializedPromise.promise;
    const completedPromise = makePromise<"never connected" | "closed">();
    this.completed = completedPromise.promise;
    this.#reportConnected = () => initializedPromise.resolve("connected");
    this.#reportClosed = () => completedPromise.resolve("closed");
    this.#reportFailed = () => {
      initializedPromise.resolve("failed");
      completedPromise.resolve("never connected");
    };
    const talkWithServer = this;
    const callbacks: Callbacks = {
      onClose(code, reason, wasClean) {
        talkWithServer.setState("done");
        console.log({ code, reason, wasClean });
      },
      onError() {
        // I'm pretty sure this is redundant.  We'll get an onClose callback.
        console.log("Error on WebSocketTunnelConnection");
      },
      onMessage(bytes) {
        talkWithServer.processDataFromServer(bytes);
      },
      onOpen() {
        if (talkWithServer.#state == "initial") {
          talkWithServer.setState("active");
        }
      },
    };
    this.#connection = new WebSocketTunnelConnection(callbacks);
  }
  get state() {
    return this.#state;
  }

  /**
   * This promise will be fulfilled as soon as we transition out of the "initial" #state.
   * I.e. as soon as we get a confirmation or an error from the network, we notify any
   * listeners.
   *
   * This promise will never reject.
   */
  readonly initialized: Promise<"connected" | "failed">;

  /**
   * This promise will be fulfilled as soon as we transition into the "done" state.
   * This would be a good time to do cleanup and possibly resend any pending requests.
   *
   * This promise will never reject.
   */
  readonly completed: Promise<"never connected" | "closed">;

  /**
   * Break the connection, if we are connected.
   *
   * Move this object into the done state.
   */
  public stop() {
    this.setState("done");
  }

  /**
   * Send the message.
   * Do not expect or process any responses.
   * @param messageToServer
   */
  public sendWithNoResponse(messageToServer: MessageToServer) {
    //TraceLoggingHelpers.log('Request, sendWithNoResponse:', messageToServer);

    getCommandName(messageToServer);
    this.sendBytes(encodeMessage(messageToServer));
  }
  #lastMessageId = 0;

  /**
   *
   * @param messageToServer What to send.
   * @returns A `CancelToken` used to abort the request.  This has the same effect as a broken network connection.
   * And a promise.  This will resolve to an array of bytes on success, or undefined in case of any error.
   * This promise will never reject.
   */
  public sendWithSingleResponse(messageToServer: MessageToServer): {cancel: CancelToken; promise: Promise<ResponseFromServer>;}
  {
  
    if (this.#state == "done") {
      // The connection is already closed.  Treat this just like a network error.
      // Immediately send undefined as a response and don't do anything else.
      return { promise: Promise.resolve(undefined), cancel() {} };
    }
    else {

      this.#lastMessageId++;
      const messageId = this.#lastMessageId;
      
      //TraceLoggingHelpers.log('Request, sendWithSingleResponse, messageId: ' + messageId, messageToServer);

      this.sendBytes(encodeMessage(messageToServer, messageId));
      const promise = makePromise<ResponseFromServer>();
      /**
       * Call this when the we received the response.
       * @param response The response received from the server, or undefined in case of error.
       */
      const forward = (response: ResponseFromServer) => {

        const traceLoggingEnabled = ConnectionSettings.getInstance().traceLogging;
        if (traceLoggingEnabled) {
          //const responseAsString = responseToString(response);
          //const xml = parseXml2(responseAsString);
          //TraceLoggingHelpers.log('Response:', xml);
        }
  
        // We are only expecting one response.  Clean up our table of listeners.
        this.#messageListeners.delete(messageId);
        promise.resolve(response);
      };
      
      const listener: InternalListener = {
        command: getCommandName(messageToServer),
        forward,
      };
      this.#messageListeners.set(messageId, listener);
      const cancel = () => {
        this.#messageListeners.delete(messageId);
        promise.resolve(undefined);
      };
      return { promise: promise.promise, cancel };
    }
  }
  /**
   * Request streaming data from the server.
   * @param messageToServer What to send to the server.
   * @param callback Where to send the responses from the server.
   * You will **never** get a call to this callback before this function returns.
   * @returns A `CancelToken`.  Use this if you don't want any more replies.
   *
   * This only affects the client communication library.  You might need a
   * separate operation to tell the server that we are done listening.
   * This tells the client to give up some memory that it no longer needs.
   * And if we get more responses from the server, this client code will
   * throw the messages away.
   */
  public sendWithStreamingResponse(messageToServer: MessageToServer, callback: (response: ResponseFromServer) => void): CancelToken
  {
    
    if (this.#state == "done")
    {
      // The connection is already closed.  Treat this just like a network error.
      // Immediately queue up undefined as a response and don't do anything else.
      setTimeout(() => {
        callback(undefined);
      }, 0);
      return () => {};
    }
    else
    {
      
      this.#lastMessageId++;
      
      const messageId = this.#lastMessageId;
      
      //TraceLoggingHelpers.log(`Request, sendWithStreamingResponse, messageId:  ${messageId}, messageToServer: `, messageToServer);

      const encodedMessage = encodeMessage(messageToServer, messageId);

      //TraceLoggingHelpers.log('Request, sendWithStreamingResponse, encodedMessage: ' + encodedMessage);

      this.sendBytes(encodedMessage);
      
      const forward = (response: ResponseFromServer) => {
        if (!response) {
          //TraceLoggingHelpers.log(`Response, sendWithStreamingResponse, forward, delete messageId:  ${messageId}`);
          this.#messageListeners.delete(messageId);
        }
        try {
          const traceLoggingEnabled = ConnectionSettings.getInstance().traceLogging;
          if (traceLoggingEnabled) {
            const responseAsString = responseToString(response);
            const xml = parseXml2(responseAsString);
            //TraceLoggingHelpers.log('Response, sendWithStreamingResponse:', xml);
          }
    
          callback(response);
        } catch (reason) {
          console.error(reason);
        }
      };
    
      const listener: InternalListener = {
        command: getCommandName(messageToServer),
        forward,
      };
    
      this.#messageListeners.set(messageId, listener);
    
      const cancel = () => {
        //TraceLoggingHelpers.log(`Response, sendWithStreamingResponse, cancel, delete messageId:  ${messageId}`);
        const wasPresent = this.#messageListeners.delete(messageId);
        /*  This caused more problems than it solved.  
            If you cancel a request it's because you don't want any more responses.
        if (wasPresent) {
          callback(undefined);
        }
        */
      };
      return cancel;
    }
  }
  /**
   * When the micro_proxy sends us a message with -1 as the id, it goes here.
   * @param body Details from the micro_proxy.
   */
  private oneServerClosed(body: ArrayLike<number>) {
    /**
     * The message is a series of lines each terminated with \n.
     * I.e. not XML.
     */
    const lines = responseToString(body).split(/\n/gm);
    const fail = (message: string) => {
      this.stop();
      console.error({ where: "oneServerClosed()", message, lines });
    };
    if (lines.length < 2) {
      fail("too short");
    }
    /**
     * The list of commands which were handled by the server that disconnected.
     */
    const commands = new Set(lines.slice(2));

    /**
     * The lowest id of any message sent to this server.
     */
    const firstId = +lines[0]; //  Should we check that this is a positive integer?  see parseIntX().

    /**
     * The largest id of any message sent to this server.
     *
     * It is possible that the client sent a series of messages with the same command,
     * most of them went to the server that was shut down.  But the micro_proxy
     * received one or more messages after it detected the problem, and it reconnected
     * and delivered the newer messages without incident.  That's why you need to
     * look at the message id numbers, not just the command.
     */
    const lastId = +lines[1];

    /**
     * For debugging and logging only.
     */
    let closeCount = 0;

    // Go through the complete list of responses that we are expecting.
    // Check each one against our criteria.
    this.#messageListeners.forEach((listener, key) => {
      if (key >= firstId && key <= lastId && commands.has(listener.command)) {
        // Clean up our local memory.
          //TraceLoggingHelpers.log(`oneServerClosed, delete messageId:  ${key}`);
        this.#messageListeners.delete(key);
        try {
          // Tell the listener that the connection was broken.
          listener.forward(undefined);
          closeCount++;
        } catch (reason) {
          // This shouldn't happen.
          console.error(reason);
        }
      }
    });
    console.log(lines, "oneServerClosed()", closeCount);
  }

  /**
   * These are the responses we are expecting.
   *
   * We use the message id to map the responses back to the original requestors.
   */
  readonly #messageListeners = new Map<number, InternalListener>();
  public get currentMessageListners() {
    return this.#messageListeners;
  }

  /**
   * This receives messages as soon as they have been parsed and un-marshaled
   * from the stream of bytes.  This sends each message to the appropriate
   * listener.
   * @param messageId
   * @param body
   */
  private processOneResponse(messageId: number, body: ArrayLike<number>) {
    if (messageId == -1) {
      // This part of our protocol.
      const responseAsString = responseToString(body);
      //TraceLoggingHelpers.log(`processOneResponse, messageId is -1, calling oneServerClosed, messageId: ${messageId}, body: `, responseAsString);
      
      this.oneServerClosed(body);
    } else {
      // This is a response to a request that we already made.
      //
      // It is **not** an error if we don't have a listener.  In that case
      // we throw the message away.  We don't want t a lot of that.  But
      // it's always possible that we cancel some request on the server
      // side, but some responses were already heading our way on the network.
      this.#messageListeners.get(messageId)?.forward(body);
    }
  }

  /**
   * processDataFromServer() might receive a partial message.  When that
   * happens, it stores that data here.  The next call to processDataFromServer()
   * will contain more data and should be combined with this.
   */
  #restartParseFrom:
    | undefined
    | { offset: number; asArray: Uint8Array | Array<number> };

  /**
   * Handle the stream of incoming bytes from the server.
   * @param body This is a series of bytes received from the server.
   */
  private processDataFromServer(body: ArrayBuffer) {
    // In principal there is no relationship between the boundaries between the responses
    // the blocks in which we receive bytes.
    //
    // In practice I often see more than one message in a single call to this function.
    // However, I've never seen a message split between multiple calls to this function.
    // I.e. this.restartParseFrom was never used outside of a special test setup.
    /**
     * Throw this if we are trying to read past the end of the buffer.
     * In that case we want to try again after more data gets added to the end of the buffer.
     */
    const TRY_AGAIN = {};
    const newDataAsArray = new Uint8Array(body);

    const message = responseToString(newDataAsArray);
    console.log(message);
    
    if (this.#restartParseFrom) {
      // Create a new array containing any unused data from the last call and all of the new data.
      this.#restartParseFrom.asArray = [
        ...this.#restartParseFrom.asArray.slice(this.#restartParseFrom.offset),
        ...newDataAsArray,
      ];
      this.#restartParseFrom.offset = 0;
    } else {
      // Work directly from the new array of bytes.
      this.#restartParseFrom = { asArray: newDataAsArray, offset: 0 };
    }
    /**
     * These are the bytes we will try to part.
     */
    const asArray = this.#restartParseFrom.asArray;
    /*
    const decoder = new TextDecoder();
    const asText = decoder.decode(body);
    console.log(asText);
    */

    /**
     * This points into asArray.
     * This says how many bytes we have consumed.
     */
    let offset = 0;

    /**
     * Compare the bytes starting at offset to the bytes in toMatch.
     *
     * If there is a match, advance `offset` to the first byte after the match.
     * (Otherwise leave `offset` alone.)
     * @param toMatch The bytes to compare to the input.
     * @returns `true` if there was a match.
     * @throws `TRY_AGAIN` if there is not enough data.
     */
    function matches(toMatch: Uint8Array): boolean {
      /**
       * How many bytes are currently available (i.e. not yet consumed) in `asArray`.
       */
      const remainingByteCount = asArray.length - offset;
      if (remainingByteCount < toMatch.length) {
        // Not enough data.
        throw TRY_AGAIN;
      }
      for (let i = 0; i < toMatch.length; i++) {
        if (toMatch[i] !== asArray[offset + i]) {
          // This byte didn't match.
          return false;
        }
      }
      // The entire array matched.
      offset += toMatch.length;
      return true;
    }
    /**
     * Check for framing.  If we find the bytes we were expecting, advance past them.
     * Otherwise, throw something.
     * @param toMatch The bytes we want to advance past.
     * @throws `TRY_AGAIN` if there is not enough data.
     * Or an `Error` if the data did not match.
     */
    function matchOrDie(toMatch: Uint8Array): void {
      if (!matches(toMatch)) {
        throw new Error("framing error");
      }
    }
    /**
     * If the next byte is a negative sign, consume it.
     * @returns 1 if this looks like a positive number, or -1 if this looks like a negative number.
     * @throws `TRY_AGAIN` if there is not enough data.
     */
    function checkForNegative(): 1 | -1 {
      if (matches(NEGATIVE_SIGN)) {
        return -1;
      } else {
        return 1;
      }
    }
    /**
     * Try to parse an integer from the input.
     * On success update offset to move past the number.
     * @returns The number read from the input.
     * @throws `TRY_AGAIN` if there is not enough data.
     * Or an `Error` if we could not find a valid number.
     */
    function getPositiveInteger(): number {
      let index = offset;
      function asDigit(char: number): number | "failed" {
        const result = char - 48;
        if (result >= 0 && result < 10) {
          return result;
        } else {
          return "failed";
        }
      }
      let result = 0;
      while (true) {
        if (index >= asArray.length) {
          throw TRY_AGAIN;
        }
        const digit = asDigit(asArray[index]);
        if (digit == "failed") {
          if (index == offset) {
            // No progress.  0 digits found.
            throw new Error("framing error (number)");
          } else {
            offset = index;
            return result;
          }
        }
        index++;
        result = result * 10 + digit;
      }
    }
    /**
     * Try to parse an integer from the input.
     * The integer might include a leading negative sign.
     * On success update offset to move past the number.
     * @returns The number read from the input.
     * @throws `TRY_AGAIN` if there is not enough data.
     * Or an `Error` if we could not find a valid number.
     */
    function getInteger(): number {
      const sign = checkForNegative();
      const positiveInteger = getPositiveInteger();
      return positiveInteger * sign;
    }
    /**
     * Consume and return the next n bytes from the input.
     * @param count Grab this many bytes.
     * @returns The bytes
     * @throws `TRY_AGAIN` if there is not enough data.
     */
    function nextNBytes(count: number): ArrayLike<number> {
      const end = offset + count;
      if (end > asArray.length) {
        throw TRY_AGAIN;
      }
      const result = asArray.slice(offset, end);
      offset = end;
      return result;
    }
    // Loop over the input until we consume all we can.
    try {
      while (offset < asArray.length) {
        // And not stopped? TODO
        matchOrDie(FRAMING1);
        const messageId = getInteger();
        matchOrDie(FRAMING2);
        const bodySize = getPositiveInteger();
        matchOrDie(FRAMING3);
        const body = nextNBytes(bodySize);
        matchOrDie(FRAMING4);
        this.processOneResponse(messageId, body);
        // If we have to TRY_AGAIN, roll back to here.
        this.#restartParseFrom.offset = offset;
      }
    } catch (reason) {
      if (reason !== TRY_AGAIN) {
        this.stop();
        console.log("error processing message", reason);
      }
    }
    if (this.#restartParseFrom.offset >= asArray.length) {
      this.#restartParseFrom = undefined;
    }
  }
}

const encoder = new TextEncoder();
/**
 * This is the first part of the a message from the server.
 * It is followed by the message id in decimal.
 */
const FRAMING1 = encoder.encode("== MESSAGE ");
/**
 * This is part of a message from the server.
 * It comes after the message id and before the size (in bytes) of the body.
 */
const FRAMING2 = encoder.encode(" ========== ");
/**
 * This is part of a message from the server.
 * It comes after the size of the body and before the body itself.
 */
const FRAMING3 = encoder.encode(" ==\r\n");
/**
 * This is the final part of a message from the server.
 * It comes after the body.
 */
const FRAMING4 = encoder.encode("\r\n");

const NEGATIVE_SIGN = encoder.encode("-");

// TODO need to export some sort of status.
// The main program might want to display some status lights.
// Traditionally we show a tick mark for each call to InternalListener.forward().
// Green when we deliver real data from the server.
// Red when we send `undefined` to say that the server is gone.
