import { parseTimeT, parseXml} from "phil-lib/misc";
import {COMMAND, CancelToken, Connection, ServerCommand} from "./connection.client";
import { IdGenerator } from "./models/helpers/id-generator";
import { AiSegment } from "./models/ai/ai-segment";
import { AiStrategy } from "./models/ai/ai-strategy";
import { AiLongTermStrategyTrade } from "./models/ai/ai-long-term-strategy-trade";
import { AiStrategyTrade } from "./models/ai/ai-strategy-trade";

class AiStrategyTradesSegment {

    listening: boolean = false;
    cancelToken: CancelToken | any;
    aiStrategyTrades: Array<AiStrategyTrade>;
    listeners: Map<string,Function>;

    constructor() {
        this.aiStrategyTrades = new Array<AiStrategyTrade>();
        this.listeners = new Map<string,Function>();
    }
}

export class AiDataManagerClient {

    private static s_instance: AiDataManagerClient | null = null;

    private id = IdGenerator.NewId();
    private aiSegments: Array<AiSegment>;
    private aiStrategies: Array<AiStrategy>;

    private aiLongTermStrategyTrades: Array<AiLongTermStrategyTrade>;
    private aiLongTermStrategyTradesListening: boolean;
    private aiLongTermStrategyTradesCancelToken: CancelToken | undefined;
    private aiLongTermStrategyTradeListeners: Map<string,Function>

    private aiStrategyTradesSegments: Map<string,AiStrategyTradesSegment>; 

    constructor() {

        this.aiSegments = [];
        this.aiStrategies = [];

        this.aiLongTermStrategyTrades = [];
        this.aiLongTermStrategyTradesListening = false;
        this.aiLongTermStrategyTradeListeners = new Map<string,Function>;
        this.aiStrategyTradesSegments = new Map<string,AiStrategyTradesSegment>

    }

    public static GetInstance(): AiDataManagerClient {
 
        if (!this.s_instance) 
            this.s_instance = new AiDataManagerClient();
         
         return this.s_instance;
    }
   
    public generateId(){
        return IdGenerator.NewId();
    }

    public async getSegments() : Promise<Array<AiSegment> | undefined> { 

        const messageToServer = [
            [COMMAND, "ai_feed_command"],
            ["subcommand", "ai_get_segments"]
          ] as ServerCommand;
      
        var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
        
        const segmentsXml = parseXml(response);

        if(!segmentsXml)
            return;

        const type = segmentsXml?.getAttribute("type")
        const countStr = segmentsXml?.getAttribute("count");
        const count = countStr ? +countStr : 0; 

        if( type != "ai_segments")
            return;

        this.aiSegments.length = 0;

        for (let i = 1; i <= count; i++) {
            
            const name = segmentsXml?.getAttribute("NAME" + i);
            const code = segmentsXml?.getAttribute("CODE" + i);
            
            if(name && code)
               this.aiSegments.push( new AiSegment(name,code))
        }
 
        return this.aiSegments;
    }

    // private async checkInitialized(){

    //     while( this.aiSegments.length == 0)
    //     {
    //         await this.getSegments();
    //     }
    // }

    public async getStrategies(segmentCode: string, windowId: string) : Promise<Array<AiStrategy> | undefined> { 


        const messageToServer = [
             [COMMAND, "ai_feed_command"],
             ["subcommand", "ai_get_strategies"],
             ["segment", segmentCode],
             ["window_id", windowId]
        ] as ServerCommand;
      
        var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
        
        const responseXml = parseXml(response);

        if(!responseXml)
            return;

        const type = responseXml?.getAttribute("type")
        const content = responseXml?.getAttribute("content") ?? "";
        const responseSegmentCode = responseXml?.getAttribute("segment");
        const responseWindowId = responseXml?.getAttribute("window_id");
        const aiSegment = this.aiSegments.find(segment => segment.code === responseSegmentCode);

        if( type != "strategies" && content != "" )
            return;

        this.aiStrategies.length = 0;

        let strategiesXml = parseXml(content); 
                    
        let strategies = strategiesXml?.querySelectorAll("STRATEGY");

        strategies?.forEach((strategy, index) => {
        
           let aiStrategy = new AiStrategy();

           aiStrategy.name = strategy?.getAttribute("NAME") ?? "";
           aiStrategy.winRate = Number(strategy?.getAttribute("OOS_WINRATE"));
           aiStrategy.profitFactor = Number(strategy?.getAttribute("OOS_PROFIT_FACTOR"));
           aiStrategy.config = (strategy?.getAttribute("CONFIG") ?? "").replace("http://www.trade-ideas.com/View.php?", "");
           aiStrategy.isLong = (strategy?.getAttribute("DIRECTION") ?? "L") == "L"; 
           aiStrategy.timeFrame =  strategy?.getAttribute("TIMEFRAME") ?? "intraday";
           aiStrategy.profitFactor = Number(strategy?.getAttribute("TOTAL_TRADES"));
           aiStrategy.maxTradesPerDay = Number(strategy?.getAttribute("MAX_TRADES_PER_DAY"));
           aiStrategy.cloudLink = strategy?.getAttribute("CLOUD_LINK") ?? "";

           let profits = strategy?.getAttribute("PROFIT_PER_DAY") ?? "";

           aiStrategy.profitPerDay = profits.split(',').map(parseFloat);

           aiStrategy.optimizedDate =  new Date(strategy?.getAttribute("DATE") ?? "");
           let timeT = Number(strategy?.getAttribute("CREATION_DATE"));
           if(timeT > 0){
              aiStrategy.creationDate = parseTimeT(timeT);
           }
           if(aiSegment){
              aiStrategy.segmentCode = aiSegment.code;
              aiStrategy.segmentName = aiSegment.name;
           }

           this.aiStrategies.push(aiStrategy);

        });

        return this.aiStrategies;
    }

    public async subscribeToAiStrategyTrades(segmentCode: string, windowId: string, callback: Function) {
        
       // await this.CheckInitialized();

        const messageToServer = [
          [COMMAND, "ai_feed_command"],
          ["subcommand", "ai_add_listener"],
          ["segment", segmentCode],
          ["window_id", windowId]
    
        ] as ServerCommand;
    
        let aiStrategyTradesSegment = this.aiStrategyTradesSegments.get(segmentCode);
        
        if(!aiStrategyTradesSegment){
            aiStrategyTradesSegment = new AiStrategyTradesSegment();
            this.aiStrategyTradesSegments.set(segmentCode,aiStrategyTradesSegment)
            aiStrategyTradesSegment.listeners.set(windowId,callback);
        } else{
            aiStrategyTradesSegment.listeners.set(windowId,callback);
            callback(windowId,aiStrategyTradesSegment.aiStrategyTrades);
            return;
        }

        Connection.getInstance().sendWithStreamingResponse(messageToServer, (response: string) => {
    
            const xml = parseXml(response);
            const epoch = xml?.getAttribute("epoch")
            const responseSegmentCode = xml?.getAttribute("segment") ?? "";
            const type = xml?.getAttribute("type")
            const responseWindowId = xml?.getAttribute("window_id");
            const aiSegment = this.aiSegments.find(segment => segment.code === responseSegmentCode);

            let aiStrategyTradesSegment = this.aiStrategyTradesSegments.get(responseSegmentCode);

            if( !aiStrategyTradesSegment) return ; 

            console.log(response);

            const csvData = xml?.innerHTML.trim() ?? "";
            
            let processedcsvData = csvData.replace(/("([^"]*)")/g, (match) => {
                return match.replace(/,/g, '~');
            });
            processedcsvData = processedcsvData.replace(/\"/g, '');


            console.log(processedcsvData);

            const rows = processedcsvData?.split('\n');

            if(rows){
               
                //clear the list 
                aiStrategyTradesSegment.aiStrategyTrades.length = 0;

                for (let i = 1; i < rows.length; i++) {
                  
                    const fields = rows[i].split(',');
                    
                    let aist = new AiStrategyTrade();
                    aist.id = fields[0];
                    aist.symbol = fields[1];
                    aist.isLong = Boolean(fields[2]);
                    aist.new = Boolean(fields[3]);
                    aist.entryTime = parseTimeT(fields[4]);
                    aist.entryPrice = Number(fields[5]);
                    aist.stopPrice = Number(fields[6]);
                    aist.targetPrice = Number(fields[7]);
                    aist.exitPrice = Number(fields[8]);
                    aist.exitTime = parseTimeT(fields[9]);
                    aist.timeStop = parseTimeT(fields[10]);
                    aist.profit = Number(fields[11]);
                    aist.exitReason = fields[12];
                    aist.smartStop = Number(fields[13]);
                    aist.last = Number(fields[14]);
                    aist.maxProfit = Number(fields[15]);
                    aist.maxProfitTime = parseTimeT(fields[16]);
                    aist.minProfit = Number(fields[17]);
                    aist.minProfitTime = parseTimeT(fields[18]);
                    aist.distanceFromStopPrice = Number(fields[19]);
                    aist.distanceFromTargetPrice = Number(fields[20]);
                    aist.profitHistory = fields[21].split('~').map(parseFloat);
                    aist.profitHistoryNonExit = fields[22].split('~').map(parseFloat);
                    aist.profitChange15 = Number(fields[23]);
                    aist.profitChange5 = Number(fields[24]);
                    aist.nonExitProfit = Number(fields[25]);
                    aist.tradeZone = fields[26];
                    aist.id2 = fields[27];
                    aist.reenterTime = parseTimeT(fields[28]);
                    aist.reenterPrice = Number(fields[29]);
                    aist.reenterStopPrice = Number(fields[30]);
                    aist.newReenter = Boolean(fields[31]);
                    aist.fullRiskProfit = Number(fields[32]);
                    aist.profitHistoryFullRisk = fields[33].split('~').map(parseFloat);;
                    aist.segmentCode = fields[34];
                    aist.segmentName = fields[35];

                    aiStrategyTradesSegment.aiStrategyTrades.push(aist);
                }
                
                aiStrategyTradesSegment.listeners.forEach((value,key) => {
                    value(key,aiStrategyTradesSegment?.aiStrategyTrades);
                });
            }

        });
     
    }

    public async subscribeToAiLongTermStrategyTrades(windowId: string, callback: Function) {
       
        const messageToServer = [
          [COMMAND, "ai_long_term"],
          ["subcommand", "ailt_add_listener"],
          ["window_id", windowId]
    
        ] as ServerCommand;

        this.aiLongTermStrategyTradeListeners.set(windowId,callback);

        if( this.aiLongTermStrategyTradesListening){
            callback(windowId,this.aiLongTermStrategyTrades);
            return;
        }
        
        this.aiLongTermStrategyTradesListening = true;

        this.aiLongTermStrategyTradesCancelToken = Connection.getInstance().sendWithStreamingResponse(messageToServer, (response: string) => {
    
            const xml = parseXml(response);

            const csvData = xml?.innerHTML.trim();
            
            const rows = csvData?.split('\n');

            if(rows){
               
                //clear the list 
                this.aiLongTermStrategyTrades.length = 0;

                //let newAiLongTermStrategyTrades = Array<AiLongTermStrategyTrade>();

                for (let i = 1; i < rows.length; i++) {
                  
                    const fields = rows[i].split(',');
                    
                    let ailtst = new AiLongTermStrategyTrade();
                    ailtst.symbol = fields[0];
                    ailtst.type = fields[1];
                    ailtst.time = parseTimeT(fields[2]);
                    ailtst.high = Number(fields[3]);
                    ailtst.low = Number(fields[4]);
                    ailtst.previousHigh = Number(fields[5]);
                    ailtst.previousLow = Number(fields[6]);
                    ailtst.previousHighTime = parseTimeT(fields[7]);
                    ailtst.previousLowTime = parseTimeT(fields[8]);
                    ailtst.entryTime = parseTimeT(fields[9]);
                    ailtst.entryPrice = Number(fields[10]);
                    ailtst.last = Number(fields[11]);
                    ailtst.daysProfitable = Number(fields[12]);
                    ailtst.totalDays = Number(fields[13]);
                    ailtst.totalChange = Number(fields[14]);
                    ailtst.totalChangePercent = Number(fields[15]);
                    ailtst.isLong = Boolean(fields[16]);
                    ailtst.mfe = Number(fields[17]);
                    ailtst.mae = Number(fields[18]);
                    this.aiLongTermStrategyTrades.push(ailtst);
                    //newAiLongTermStrategyTrades.push(ailtst);
                }

                this.aiLongTermStrategyTradeListeners.forEach((value,key) => {
                    value(key,this.aiLongTermStrategyTrades);
                });

            }
           
        });
     
    }
    
    public getCurrentAiLongTermStrategyTrades() : Array<AiLongTermStrategyTrade> {
        
        if( !this.aiLongTermStrategyTradesListening){
            throw new Error("Not Subscribed to AiLongTermStrategyTrades. Please call subscribeToAiLongTermStrategyTrades() first.");
        }

        return this.aiLongTermStrategyTrades;
           
    }

    public unsubscribeToAiLongTermStrategyTradeUpdates(windowId: string) {
     
        const messageToServer = [
          [COMMAND, "ai_long_term"],
          ["subcommand", "ailt_remove_listener"],
          ["window_id", windowId]
        ] as ServerCommand;
        
        Connection.getInstance().sendWithNoResponse(messageToServer);

        this.aiLongTermStrategyTradeListeners.delete(windowId);

    }
}



// object[] message = new object[]
// {
// "command", "ai_feed_command",
// "subcommand", "ai_trade_history",
// "from", fromDate,
// "to", toDate,
// "segment", _segmentCode,
// "min_profit", minProfit,
// "max_profit", maxProfit
// };

//<API epoch="1718079008" segment="main" type="status" window_id="A1">
//ID,SYMBOL,IS_LONG,NEW,ENTRY_TIME,ENTRY_PRICE,STOP_PRICE,TARGET_PRICE,EXIT_PRICE,EXIT_TIME,TIME_STOP,PROFIT,EXIT_REASON,SMART_STOP,LAST,MAX_PROFIT,MAX_PROFIT_TIME,MIN_PROFIT,MIN_PROFIT_TIME,DISTANCE_FROM_STOP_PRICE,DISTANCE_FROM_TARGET_PRICE,PROFIT_HISTORY,PROFIT_HISTORY_NONEXIT,PROFIT_CHANGE_15,PROFIT_CHANGE_5,NONEXIT_PROFIT,TRADE_ZONE,ID2,REENTER_TIME,REENTER_PRICE,REENTER_STOP_PRICE,NEW_REENTER,FULL_RISK_PROFIT,PROFIT_HISTORY_FULLRISK,SEGMENT,SEGMENT_NAME
//1718113201-AISP-1,AISP,1,0,1718113201,4.07,3.6399999332428,0,0,0,1718135400,0.18259999999999987,,0.3049999475479126,4.2526,0.34499999999999975,1718113380.0,0,1718113201,0.6126000667572002,1,"0.06,0.32,0.345,0.27,0.183","0.06,0.32,0.345,0.27,0.183",0.18259999999999987,0,0.18259999999999987,1,1718113201-AISP-aws1-1,0,3.8549999666214,0,0,0.18259999999999987,"0.06,0.32,0.345,0.27,0.183",aws1,Holly Neo
//1718113283-CXW-0,CXW,0,0,1718113283,11.445,11.778700180054,0,0,0,1718135400,0.015000000000000568,,0.5193500518798828,11.43,0.13490000000000002,1718113380.0,-0.20500000000000007,1718113440.0,0.34870018005400105,1,"0.095,0.09,-0.185,0.015","0.095,0.09,-0.185,0.015",0.015000000000000568,0,0.015000000000000568,1,1718113283-CXW-aws1-0,0,11.611850090027001,0,0,0.015000000000000568,"0.095,0.09,-0.185,0.015",aws1,Holly Neo"

// symbol,type,timet,high,low,previous_high,previous_low,previous_high_timet,previous_low_timet,entry_timet,entry_price,last,days_profitable,total_days,total_change,total_change_percent,is_long,mfe,mae
// DBI,New Low,1717680600,,7.39,,7.87,,1717680600,1717511770,8.69,7.87,2,3,0.8199999999999994,9.436133486766392,0,7.87,9.5
// NTNX,New Low,1717680600,,51.83,,52.1,,1717680600,1717077268,57.7541,52.34,6,6,5.414099999999998,9.374399393289822,0,52.1,63.25
// OZK,New Low,1716989400,,38.1505,,38.39,,1716989400,1716990016,40.86,39.01,5,7,1.8500000000000014,4.527655408712681,0,38.39,42.32
// ALRM,New Low,1717507800,,62.3,,62.51,,1717507800,1716915631,67.58,62.93,8,8,4.649999999999999,6.880733944954126,0,62.51,67.865
// CHWY,New High,1717767600,24.17,,24.01,,1717680600,,1716990115,20.2602,23.85,7,7,3.5898000000000003,17.718482542126928,1,24.01,19.16
// SUN,New High,1717680300,54.63,,52.38,,1717680600,,1716563553,50.21,52.37,6,9,2.1599999999999966,4.301931886078464,1,52.38,49.45
// GOGL,New Low,1717594200,,13.795,,13.81,,1717594200,1716385383,14.29,14.15,5,11,0.1399999999999988,0.9797060881735394,0,13.81,14.82
// CVAC,New High,1717673400,5.28,,4.57,,1717680600,,1715954548,3.91,4.53,4,14,0.6200000000000001,15.856777493606138,1,4.57,3.25
// LWAY,New Low,1717507800,,12.6315,,12.8201,,1717507800,1715695102,24.7,12.94,17,17,11.76,47.611336032388664,0,12.8201,26.1799
// TREX,New Low,1717594200,,81.48,,82.31,,1717594200,1715348438,90.2,82.89,18,19,7.310000000000002,8.104212860310424,0,82.31,92.02
// PGY,Crossover Long,1717680600,12.49,,,,,,1715263207,11.98,11.81,4,20,-0.16999999999999993,-1.4190317195325537,1,13.22,10.85
// COMM,New High,1717675200,1.8,,1.75,,1717680600,,1715262093,1.21,1.72,11,20,0.51,42.14876033057852,1,1.75,1.0
// NVAX,New High,1717673400,23.8599,,22.24,,1717680600,,1715348426,10.2886,20.97,18,19,10.681399999999998,103.81781777890089,1,22.24,8.61
// INOD,New High,1717686900,15.74,,15.3,,1717680600,,1715175637,9.5,15.1,21,21,5.6,58.94736842105262,1,15.3,8.2102
// TGLS,New Low,1715261400,,46.77,,47.9958,,1715261400,1715089557,52.69,49.08,10,22,3.6099999999999994,6.851394951603719,0,47.9958,57.27
// JELD,Crossover Short,1717680600,,14.45,,,,,1715089235,14.8,15.16,5,22,-0.35999999999999943,-2.4324324324324285,0,13.62,15.78
// JMIA,New High,1717673400,8.17,,7.81,,1717680600,,1715089812,6.51,7.76,10,22,1.25,19.201228878648237,1,7.81,5.55
// EBS,New High,1717680600,7.18,,6.71,,1717680600,,1714657200,3.24,6.67,25,25,3.4299999999999997,105.86419753086417,1,6.71,2.82
// WGS,New High,1717680300,28.5432,,27.28,,1717680600,,1714484490,15.05,27.28,27,27,12.23,81.2624584717608,1,27.28,13.9
// WISA,Crossover Long,1717684800,3.7799,,,,,,1713274810,2.77,2.07,25,37,-0.7000000000000002,-25.270758122743686,1,10.95,1.9097
// FAST,New Low,1717507800,,63.25,,63.79,,1717507800,1712843419,70.34,65.19,39,40,5.150000000000006,7.321580892806377,0,63.79,73.43
// ALCC,New High,1712583000,18.8,,17.49,,1712583000,,1712583616,14.98,15.17,2,23,0.1899999999999995,1.2683578104138817,1,17.49,12.25
// ASPN,New High,1717675200,31.74,,31.53,,1717680600,,1714657205,21.15,31.42,25,25,10.270000000000003,48.55791962174943,1,31.53,19.33